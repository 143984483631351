import React, {useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import bkg from "../../Assets/home-bg.jpg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

function Home() {

     useEffect(() => {
       ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Home Page" });
     }, []);
     
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Akash Rajmane | Portfolio</title>
          <meta
            name="description"
            content="Welcome to the portfolio website of Akash Rajmane, an experienced Full Stack Developer skilled in ReactJS, React Native, JavaScript, NodeJS, and more. Explore my projects, skills, and expertise in web and mobile development."
          />
          <link rel="canonical" href="https://akashrajmane.site" />
          <meta
            name="keywords"
            content="Akash Rajmane, Full Stack Developer, ReactJS, React Native, JavaScript, NodeJS, Web Development, Mobile Development, Portfolio"
          />
          <meta name="author" content="Akash Rajmane" />
          <meta
            name="google-adsense-account"
            content="ca-pub-1746156660192738"
          ></meta>
          <link rel="preload" as="image" href={homeLogo} />
          <link rel="preload" href={bkg} as="image" />
        </Helmet>
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h2 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h2>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> Akash Rajmane !</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
