import React from "react";
import { Container, Row } from "react-bootstrap";


function Footer() {
 
  return (
    <Container fluid className="footer">
      <Row>
        <h3>
          Innovating with React, Node.js, and cutting-edge web technologies to
          create exceptional user experiences.
        </h3>
      </Row>
    </Container>
  );
}

export default Footer;
