import React, { useState, useEffect, lazy, Suspense } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Footer from "./components/Footer";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4";
import TopmateButton from "./components/TopmateBtn";
import LazyLoader from "./components/LazyLoader";


const About = lazy(() => import("./components/About/About"));
const Projects = lazy(() => import("./components/Projects/Projects"));


ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);


function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 100);


    return () => {
      clearTimeout(timer);
    };
  }, []);

  
  return (
    <Router>
      {load ? <LazyLoader show={true} />:
      <div className="App" id={load ? "no-scroll" : ""}>
        <Navbar />
        <ScrollToTop />
        <Suspense fallback={<LazyLoader show={true} />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={ <Projects />}/>
          <Route path="/about" element={<About/>} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        </Suspense>
        <TopmateButton/>
        <Footer />
      </div>
      }
    </Router>
  );
}

export default App;
