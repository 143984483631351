import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/akashrajmane-image.jpeg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h2 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h2>
            <p className="home-about-body">
              My name is Akash Rajmane. I have over 2.5+ years of experience as a
              Software Developer, focusing on creating immersive user
              experiences using technologies like ReactJS, React Native,
              TypeScript, HTML5, CSS3, and JavaScript, NodeJS, Express, MongoDB, MySQL, ThreeJS, React Three Fiber.
              I'm currently working at
              CodingEra Software Pvt Ltd, where I've been involved in developing
              and enhancing various applications, Before this, I worked as an
              Associate Software Engineer at Tech Mahindra, where I specialized
              in building reusable components, which significantly improved our
              team's efficiency. I also have a strong foundation in design
              principles, Web Content Accessibility Guidelines (WCAG), and
              optimization techniques. I'm passionate about learning new
              technologies and constantly improving my skill set to deliver
              high-quality software solutions.
              <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="purple">Web Technologies and Products </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js, ReactNative and Next.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img
                src={myImg}
                className="img-fluid rounded-circle"
                alt="akash rajmane pic"
              />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h2>FIND ME ON</h2>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/Akash-Rajmane"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="Akash Rajmane's Github Profile"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/akash-rajmane/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                  aria-label="Akash Rajmane's LinkedIn Profile"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
