import React from "react";
import ReactGA from "react-ga4";


const TopmateButton = () => {
  
  const handleClick = () => {
    ReactGA.event({
      category: "Topmate Button",
      action: "Clicked Get My Guidance on Topmate Button",
    });
    window.open("https://topmate.io/akashrajmane", "_blank");
  };

  return (
    <button
      onClick={handleClick}
      style={{
        backgroundColor: "#000",
        color: "#fff",
        border: "1px solid #000",
        padding: "10px 20px",
        fontSize: "16px",
        fontWeight: "500",
        borderRadius: "25px",
        cursor: "pointer",
        position: "fixed",
        right: "30px",
        bottom: "30px",
        zIndex: 1000,
      }}
    >
      Get My Guidance on Topmate
    </button>
  );
};

export default TopmateButton;
